<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <!-- form: {{ form }} -->
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="salesmen"
                        name="salesmen"
                        :label="getDictionary('salesman')"
                        v-model="form.mappable_id"
                        :options="opt_salesmen"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="map"
                        :name="beForm[rep].map.label"
                        :label="beForm[rep].map.label"
                        v-model="form[rep].map"
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].insurance_ancillary_migrate.label"
                        :label="beForm[rep].insurance_ancillary_migrate.label"
                        v-model="form[rep].insurance_ancillary_migrate"
                        :options="
                          beForm[rep].insurance_ancillary_migrate.options
                        "
                        :rules="{ required: true }"
                      />
                    </div>
                    <div
                      v-if="form[rep].insurance_ancillary_migrate === 'Y'"
                      class="form-group col-md-3"
                    >
                      <base-datepicker
                        :name="beForm[rep].insurance_ancillary_date.label"
                        :label="beForm[rep].insurance_ancillary_date.label"
                        v-model="form[rep].insurance_ancillary_date"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].book_entry_migrate.label"
                        :label="beForm[rep].book_entry_migrate.label"
                        v-model="form[rep].book_entry_migrate"
                        :options="beForm[rep].book_entry_migrate.options"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div
                      v-if="form[rep].book_entry_migrate === 'Y'"
                      class="form-group col-md-3"
                    >
                      <base-datepicker
                        :name="beForm[rep].book_entry_date.label"
                        :label="beForm[rep].book_entry_date.label"
                        v-model="form[rep].book_entry_date"
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
// import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseSelect from "@/components/form/BaseSelect";
// import CustomInputs from "@/components/form/CustomInputs";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import BaseDatepicker from "@/components/form/BaseDatepicker";
// import BaseCurrency from "@/components/form/BaseCurrency";
// import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
// import { calcPolicyDate } from "@/utils/dates";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
// import { RepositoryFactory } from "@/repositories/RepositoryFactory";
// import { prepareFilterQueryString } from "@/utils/forms";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "import_map",
      filter: this.initFilter(),
      isLoading: false,
      // isLoadingAjax: false,
      // isImported: false,
      // isImportedStatusPolicy: false,
      // disabled: false,
      // ajaxOptions: [],
      // opt_salesmen: [],
      // mandate_code_options: [],
      // companies: [],
      // risk_branches: [],
      // risks: [],
      // products: [],
      id: this.$route.params.id,
      detailsData: this.$route.params.data.details,
      // item: this.$route.params.item,
      // registry_data: null,
      // suspendedAt: true,
      // cancellaedAt: true,
      opt_salesmen: [],
      // opt_ancillary: [
      //   { value: "Y", text: "Sì" },
      //   { value: "N", text: "No" },
      // ],
      // opt_bookentry: [
      //   { value: "Y", text: "Sì" },
      //   { value: "N", text: "No" },
      // ],
      titoli: false,
      movimenti: false,
      form: {
        mappable_id: null,
        import_map: {
          insurer_id: null,
          broker: {},
          map: null,
          insurance_ancillary_migrate: null,
          insurance_ancillary_date: null,
          book_entry_migrate: null,
          book_entry_date: null,
        },
      },
      mandatoryFields: {
        import_map: {},
      },
    };
  },
  props: {},
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    // BaseCheckbox,
    // CustomInputs,
    BaseDatepicker,
    // BaseCurrency,
    // QuickSearchRegistryModal,
  },
  methods: {
    toInfoData,
    initFilter() {
      let init = {
        byAncillary: null,
        byCalendarAncillary: {
          from: null,
        },
        byBookEntry: null,
        byCalendarBookEntry: {
          from: null,
        },
      };
      return init;
    },
    setMandatoryField() {
      this.form[this.repository].broker = {
        [this.form.mappable_id]: {},
      };
    },
    // onUpdate() {
    //   this.isLoading = true;
    //   this.setMandatoryField();
    //   this.update(this.repository, this.id)
    //     .then(() => {
    //       this.$emit("fetch");
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({
    //         preset: "error",
    //         text: `${errMsg}`,
    //       });
    //       this.isLoading = false;
    //     });
    // },
    onSubmit() {
      this.isLoading = true;
      this.setMandatoryField(); // { broker: { id: ... } }

      // spostalo *(QUI)

      /*
      TODO:
      Nel contesto di UPDATE vanno inviati a BE:
      - il form
      - alcuni parametri extra
      Definire:
      - la rotta da utilizzare (update/filter-update?)
      - il formato dei parametri extra stessi
      - la loro pozizione (form/querystring?)
      */
      // let payload = { ...this.mandatoryFields["import_map"] };
      // this.setMandatoryValues("import_map", payload);
      // payload = { ...payload, ...this.form["import_map"] };
      // const filterString = prepareFilterQueryString(this.filter);
      // const Repo = RepositoryFactory.get("import_map");
      // console.log(filterString);
      // per la filter_update, impostare questo json:
      // // payload = {
      // //   import_map: this.id,
      // // };
      // payload.import_map = this.id;
      // Repo.filter_update(payload, filterString)
      this.update(this.repository, this.id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Mappatura migrata`,
          });
          // this.$router.go(-1);
          // this.$router.push({ name: "import_maps.index0" });
          // this.shortcut("import_maps.index0", null, "#Brokers");
          // this.shortcut("import_maps.index0", null, "#Brokers");
          this.shortcut(
            "module.PROF.CONF",
            null,
            "#Brokers",
            "filterImportMapsBroker",
            null,
            {
              /* fromShortcut: true */
            },
            [
              {
                text: "Profilo",
                path: "",
                tab: "",
                level: 0,
                disabled: true,
              },
              {
                text: "Mappatura Importatori",
                path: "module.PROF.CONF",
                tab: "",
                level: 1,
              },
              {
                text: "Mappatura Produttori",
                path: "module.PROF.CONF",
                tab: "#Brokers",
                level: 2,
              },
            ]
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      // getInsurers: "insurers",
      // getBranches: "branches",
      // getRisks: "risks",
      // getProducts: "products",
      // getMandateCodes: "mandate_codes",
    }),
  },
  created() {
    this.isLoading = true;
    this.opt_salesmen = this.getSalesmen();
    this.fetchEditForm(this.repository, this.id)
      .then(() => {
        console.log("this.beForm", this.beForm);
        console.log("this.form", this.form);
        this.form.mappable_id = this.detailsData.mappable_id;
        /* this.companies = this.getInsurers();
      this.risk_branches = this.getBranches()(this.form[this.rep].insurer_id);
      // carica mandate_code
      this.mandate_code_options = this.getMandateCodes()(
        this.form[this.rep].insurer_id
      );
      this.risks = this.getRisks();
      this.form.risk_branch_id = this.getProducts()().find(
        (product) => product.value === this.form[this.rep].insurance_risk_id
      ).risk_branch_id;
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        this.form.risk_branch_id,
        this.form.risk_id
      );
      const Repo = RepositoryFactory.get("registry");
      Repo.show(this.beForm[this.rep].registry_id.value)
        .then((response) => {
          const data = response.data.data;
          this.registry_data = data;
          this.form[this.rep].registry_id =
            this.beForm[this.rep].registry_id.value;
          this.form.inpt_label = this.toInfoData(
            this.registry_data,
            "registryfullname"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        }); */
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  computed: {
    rep() {
      return this.repository;
    },
    // cor() {
    //   return "correspondence";
    // },
    // prdct() {
    //   return this.products;
    // },
  },
  // watch: {
  //   "form.inpt_label": {
  //     deep: true,
  //     handler() {
  //       if (!this.form[this.rep].registry_id) {
  //         this.registry_data = null;
  //       }
  //     },
  //   },
  // },
};
</script>
