<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <b-card header="Dati Principali" header-tag="header" class="mt-1">
          <b-card-text>
            <b-row>
              <div class="col-md-4 border-bottom">Codice Compagnia</div>
              <div class="col-md-8 border-bottom">{{ dbRowData.map }}</div>
            </b-row>
            <b-row>
              <div class="col-md-4 border-bottom">Compagnia</div>
              <div class="col-md-8 border-bottom">
                {{
                  dbRowData["insurer"] ? dbRowData.insurer.formatted_title : ""
                }}
              </div>
            </b-row>
          </b-card-text>
        </b-card>

        <div v-if="!dbRowData.mappable_id">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card
                header="Mappatura Forme di pagamento - associa una forma di pagamento"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <div class="form-group col-md-8">
                    <base-select
                      name="salesmen"
                      vid="salesmen"
                      label="Forme di pagamento"
                      v-model="form.mappable_id"
                      :options="opt_treasuries"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onUpdate)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Associa
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          <b-card header="Forma di Pagamento" header-tag="header" class="mt-1">
            <b-card-text>
              <b-row>
                <div class="col-md-4 border-bottom">Forma di pagamento</div>
                <div class="col-md-8 border-bottom">
                  {{
                    dbRowData.mappable.code +
                    "-" +
                    dbRowData.mappable.formatted_title
                  }}
                </div>
              </b-row>
            </b-card-text>
          </b-card>
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Caricamento in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";

export default {
  name: "General",
  mixins: [FormMixin, ShowMixin],
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseIcon,
    BaseSelect,
  },
  data() {
    return {
      repository: "import_map",
      isLoading: false,
      opt_treasuries: [],
      form: {
        mappable_id: null,
        import_map: {
          insurer_id: null,
          treasury: {},
          map: null,
        },
      },
    };
  },
  methods: {
    onUpdate() {
      this.isLoading = true;
      this.setMandatoryField();
      this.update(this.repository, this.resourceId)
        .then(() => {
          this.$emit("fetch");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    setMandatoryField() {
      this.form[this.repository].insurer_id = this.dbRowData.insurer_id;
      this.form[this.repository].map = this.dbRowData.map;
      this.form[this.repository].treasury = {
        [this.form.mappable_id]: {},
      };
    },
    ...mapGetters("auth", {
      getAllTreasuries: "allTreasuries",
    }),
  },
  mounted() {
    this.opt_treasuries = this.getAllTreasuries();
  },
};
</script>
