<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <b-card header="Dati Principali" header-tag="header" class="mt-1">
          <b-card-text>
            <b-row>
              <div class="col-md-4 border-bottom">Codice Compagnia</div>
              <div class="col-md-8 border-bottom">{{ dbRowData.map }}</div>
            </b-row>
            <b-row>
              <div class="col-md-4 border-bottom">Compagnia</div>
              <div class="col-md-8 border-bottom">
                {{
                  dbRowData["insurer"] ? dbRowData.insurer.formatted_title : ""
                }}
              </div>
            </b-row>
          </b-card-text>
        </b-card>

        <div v-if="!dbRowData.mappable_id">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card
                header="Mappatura Compagnie Coass - associa una Compagnia Coass"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <div class="form-group col-md-8">
                    <base-select
                      name="insurer_participants"
                      vid="insurer_participants"
                      label="Compagnie Coass"
                      v-model="form.mappable_id"
                      :options="opt_insurer_participant"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onUpdate)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Associa
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          <b-card header="Compagnia Coass" header-tag="header" class="mt-1">
            <b-card-text>
              <b-row>
                <div class="col-md-4 border-bottom">Compagnie Coass</div>
                <div class="col-md-8 border-bottom">
                  {{ getInsurerParticipant(dbRowData) }}
                </div>
              </b-row>
            </b-card-text>
          </b-card>
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Caricamento in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  name: "General",
  mixins: [FormMixin, ShowMixin],
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseIcon,
    BaseSelect,
  },
  data() {
    return {
      repository: "import_map",
      isLoading: false,
      opt_insurer_participant: [],
      form: {
        mappable_id: null,
        import_map: {
          insurer_id: null,
          insurer_participant: {},
          map: null,
        },
      },
    };
  },
  methods: {
    onUpdate() {
      this.isLoading = true;
      this.setMandatoryField();
      this.update(this.repository, this.resourceId)
        .then(() => {
          this.$emit("fetch");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    getInsurerParticipant(insurer_participant) {
      return `${
        insurer_participant?.mappable?.code
          ? insurer_participant.mappable.code
          : ""
      }-
      ${
        insurer_participant?.mappable?.formatted_title
          ? insurer_participant.mappable.formatted_title
          : ""
      }`;
    },
    setMandatoryField() {
      this.form[this.repository].insurer_id = this.dbRowData.insurer_id;
      this.form[this.repository].map = this.dbRowData.map;
      this.form[this.repository].insurer_participant = {
        [this.form.mappable_id]: {},
      };
    },
  },
  mounted() {
    this.isLoading = true;
    const Repo = RepositoryFactory.get("insurer_participant");
    Repo.index("perPage=0")
      .then((response) => {
        const data = response.data.data;
        this.opt_insurer_participant = data.map((e) => {
          return {
            value: e.id,
            text: `${e.code} - ${e.title}`,
            code: `${e.code}`,
          };
        });
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({ preset: "error", text: errMsg });
        this.isLoading = false;
      });
  },
};
</script>
