<template>
  <div class="mt-1 mx-3">
    <brokers> </brokers>
  </div>
</template>

<script>
import Brokers from "@/components/profile/import-maps/broker/index.vue";
import { mapActions } from 'vuex';

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Brokers,
  },
  created() {
    this.notifyQueue({ text: "Mappatura Produttori", path: "import_maps.index0", level: 1 });
  },
};
</script>
