<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="insurers"
                label="Compagnie"
                :options="companies"
                v-model="filter.byInsurer.id"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="mappable_id"
                label="Mappato"
                v-model="filter.byImport"
                :options="opt_map"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="map"
                type="text"
                label="Codice Compagnia"
                v-model="filter.byAttribute.map"
                placeholder="Inserisci un codice compagnia"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <import-maps
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: ['byInsurer'],
      }"
      :onlyActions="['infomodal']"
      :ref="tableRef"
    ></import-maps>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import ImportMaps from "@/components/tables/ImportMapsTreasury.vue";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      repository: "import_map",
      resource: "import_maps",
      tableRef: "ImportMapsTreasuryTableRef",
      filterName: "filterImportMapsTreasury",
      filter: this.initFilter(),
      companies: [],
      opt_map: [{ text: "No", value: "treasury" }],
      fields: [
        {
          key: "insurer.formatted_title",
          label: this.getDictionary("map", "import_map"),
          sortable: true,
          sortKey: "byInsurer.formatted_title",
        },
        {
          key: "map",
          label: this.getDictionary("code", "insurer"),
          sortable: true,
          sortKey: "map",
        },
        {
          key: "mapped",
          label: this.getDictionary("insurer_id", "import_map"),
        },
        {
          key: "treasury",
          label: this.getDictionary("title", "treasury"),
        },
      ],
    };
  },
  components: {
    ImportMaps,
    BaseSelect,
    BaseInput,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          mappable_temp: "treasury",
          mappable_id: null,
          map: null,
        },
        byImport: null,
        byInsurer: {
          id: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },

    ...mapGetters("auth", {
      getInsurers: "insurers",
    }),
  },
  mounted() {
    this.companies = this.getInsurers();
  },
};
</script>
