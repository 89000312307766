<template>
  <div class="mt-1 mx-3">
    <treasuries> </treasuries>
  </div>
</template>

<script>
import Treasuries from "@/components/profile/import-maps/treasury/index.vue";
import { mapActions } from 'vuex';

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Treasuries,
  },
  created() {
    this.notifyQueue({ text: "Mappatura Forme di Pagamento", path: "import_maps.index1", level: 1 });
  },
};
</script>
